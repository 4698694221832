import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { random } from 'lodash';
import { evaluateFeature } from 'feature-management-evaluation-engine';
const isThere = underQuestion => Boolean(underQuestion);

// Moving codebases? Start a conversation about whether
// we should include this in the engine's repo
export const evaluateFeatureCollection = (featureCollection, context, overrideSeed) => {
  // eslint-disable-next-line no-magic-numbers
  const seed = overrideSeed !== null && overrideSeed !== void 0 ? overrideSeed : random(0, 2 ** 32 - 1); // 0 to uint32 max (4294967295)

  const results = featureCollection.map(featureRules => {
    const result = evaluateFeature(featureRules, context, seed);
    return evaluationResultToFlag(featureRules, result);
  }).filter(isThere);
  return {
    seed,
    results: results.reduce((results, result) => _objectSpread(_objectSpread({}, results), {}, {
      [result.flag]: result
    }), {}),
    flags: results.reduce((flags, result) => _objectSpread(_objectSpread({}, flags), {}, {
      [result.flag]: result.value
    }), {})
  };
};
export const evaluationResultToFlag = (featureRules, result) => {
  var _result$cohortName;
  if (!result || result.value === null) {
    return null;
  }
  const baseFlagSlug = extractFlagSlugFromFeatureSlug(featureRules.featureSlug);
  const featureManagementCohortNameData = extractFeatureManagementDataFromCohortName(baseFlagSlug, (_result$cohortName = result.cohortName) !== null && _result$cohortName !== void 0 ? _result$cohortName : '');
  return _objectSpread(_objectSpread({
    featureSlug: featureRules.featureSlug,
    value: result.value
  }, {
    cohortName: result.cohortName,
    flag: flagSlugToFlag(baseFlagSlug)
  }), featureManagementCohortNameData !== null && featureManagementCohortNameData !== void 0 ? featureManagementCohortNameData : {});
};
const flagSlugToFlag = flagSlug => "NEXT_PUBLIC_FEAT_FLAG_".concat(flagSlug.toUpperCase().split('-').join('_'));
const extractFlagSlugFromFeatureSlug = featureSlug => {
  const slugParts = featureSlug.split('/');
  const flagSlug = slugParts[slugParts.length - 1];
  return flagSlug;
};
const extractFeatureManagementDataFromCohortName = (baseSlug, maybeFeatureManagementCohortName) => {
  var _maybeFeatureManageme;
  if (!maybeFeatureManagementCohortName.startsWith('FM:')) {
    return undefined;
  }
  const [_, cohortName, flagSlugSuffix] = (_maybeFeatureManageme = maybeFeatureManagementCohortName.split(':').flatMap(part => part.split('|'))) !== null && _maybeFeatureManageme !== void 0 ? _maybeFeatureManageme : [];
  if (!cohortName) {
    return undefined;
  }
  const flagSlug = flagSlugSuffix ? [baseSlug, flagSlugSuffix].join('-') : baseSlug;
  return {
    cohortName,
    flag: flagSlugToFlag(flagSlug)
  };
};