import { theme as chakraTheme } from '@chakra-ui/react'
import { mapKeys, mapValues } from 'lodash'
import foundations from '@scentregroup/hub-foundations'

type MappedKeysWithPrefix<Prefix extends string, Type> = {
  [Property in keyof Type as `${Prefix}${string & Property}`]: Type[Property]
}

const prefixKeys = <Prefix extends string, T extends Record<string, string>>(
  obj: T,
  prefix: Prefix
): MappedKeysWithPrefix<Prefix, T> =>
  mapKeys(obj, (value, key) => `${prefix}${key}`) as MappedKeysWithPrefix<
    Prefix,
    T
  >

export const colors = {
  brand: '#f22d45',
  white: '#ffffff',
  success: '#51722c',
  successLight: '#deedd3',
  warning: '#985a00',
  warningLight: '#fae6b3',
  error: '#c52d2f',
  errorLight: '#fee1e1',
  info: '#1663ac',
  infoLight: '#bee3f8',
  seasonal: '#7d479b',
  seasonalLight: '#a96bcc',
  seasonalContrast: '#ffffff',
  licorice: '#161719', // black
  quartz: '#58585f', // greyDark
  manatee: '#a2a2a9',
  ghost: '#d9dadd', // grey
  smoke: '#f5f6f7', // greyLight
  blueCharcoal: '#1f2038',
  vibrantTeal: '#0dd9cb',
  blue: '#4377b7', // blue - used in new promotion & rewards pages
  // The following colors will probably need to
  // be lifted into proper tokens in hub foundations:
  surfacePrimaryDisabled: '#f5f6f7',
  textDisabled: '#d9dadd',
  iconDisabled: '#d9dadd',
}

const foundationPalette = foundations.color.palette

// A namespaced and detailed version of `colors` to eventually (& hopefully) replace `colors`.
export const colorPalette = {
  digital: {},
  accent: {
    vibrantTeal: {
      value: colors.vibrantTeal,
      usage: 'Accent colour for Westfield Direct',
      compliance: '',
    },
    blueCharcoal: {
      value: colors.blueCharcoal,
      usage: 'Accent colour for Westfield Direct',
      compliance: '',
    },
  },
  alert: {
    error: {
      value: colors.error,
      usage: 'Error message',
      compliance: '',
    },
    errorLight: {
      value: colors.errorLight,
      usage: 'Error background',
      compliance: '',
    },
    success: {
      value: colors.success,
      usage: 'Success message',
      compliance: '',
    },
    successLight: {
      value: colors.successLight,
      usage: 'Success background',
      compliance: '',
    },
    warning: {
      value: colors.warning,
      usage: 'Warning message',
      compliance: '',
    },
    warningLight: {
      value: colors.warningLight,
      usage: 'Warning background',
      compliance: '',
    },
  },
}

const foundationColors = mapValues(foundationPalette, value => value.value)

export const sizeScale = {
  // NOTE: For use in `calc()`, it's important that size '0' has a unit
  '0': '0px',
  '1': '4px', //   0 +  4
  '2': '8px', //   4 +  4
  '3': '12px', //   8 +  4
  '4': '16px', //  12 +  4
  '5': '24px', //  16 +  8
  '6': '32px', //  24 +  8
  '7': '40px', //  32 +  8
  '8': '48px', //  40 +  8
  '9': '56px', //  48 +  8
  '10': '64px', //  56 +  8
  '11': '80px', //  64 + 16
  '12': '96px', //  80 + 16
  '13': '112px', //  96 + 16
  '14': '128px', // 112 + 16
  '15': '144px', // 128 + 16
  '16': '160px', // 144 + 16
  '17': '192px', // 160 + 32
  '18': '224px', // 192 + 32
  '19': '256px', // 224 + 32
  '20': '288px', // 256 + 32
  '21': '320px', // 288 + 32
  '22': '352px', // 320 + 32
  '23': '416px', // 352 + 64
  '24': '480px', // 416 + 64
  '25': '544px', // 480 + 64
  '26': '608px', // 544 + 64
  '27': '672px', // 608 + 64
} as const

export const lineHeights = {
  none: '1em',
  shorter: '1.25em',
  base: '1.5em',
}

export const iconSizes = {
  'size-icon-sm': sizeScale[4],
  'size-icon-md': sizeScale[5],
  'size-icon-lg': sizeScale[8],
  'size-icon-xl': sizeScale[10],
  ...prefixKeys(lineHeights, 'size-icon-line-height-'),
}

export const typographyScale = {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  '2xl': '24px',
  '3xl': '28px',
  '4xl': '32px',
  '5xl': '40px',
  '6xl': '48px',
  '7xl': '52px',
} as const

const {
  Button: _Button,
  Tabs: _Tabs,
  Avatar: _Avatar,
  Input: _Input,
  ...componentThemes
} = chakraTheme.components

export const theme = {
  ...chakraTheme,
  semanticTokens: {
    colors: {
      'chakra-body-text': { _light: foundationColors.textPrimary },
    },
  },
  space: {
    ...prefixKeys(sizeScale, 'spacing-'),
    // named spacing
    measure: '60ch',
    'spacing-none': sizeScale[0],
    'spacing-xs': sizeScale[1],
    'spacing-sm': sizeScale[2],
    'spacing-md': sizeScale[4],
    'spacing-lg': sizeScale[5],
    'spacing-xl': sizeScale[6],
    'spacing-2xl': sizeScale[7],
    'spacing-3xl': sizeScale[8],
    'spacing-4xl': sizeScale[9],
    'spacing-5xl': sizeScale[10],
    'spacing-mobile-margin': '20px',

    // required for some of chakra-ui's features to have the same naming across space and size
    // main example is input elements for displaying things inside input fields
    ...prefixKeys(sizeScale, 'size-'),
  },
  sizes: {
    ...prefixKeys(sizeScale, 'size-'),
    // named sizes
    'size-none': sizeScale[0],
    'size-xs': sizeScale[6],
    'size-sm': sizeScale[10],
    'size-md': sizeScale[14],
    'size-lg': sizeScale[16],
    'size-xl': sizeScale[18],
    'size-2xl': sizeScale[20],
    'size-3xl': sizeScale[22],
    'size-4xl': sizeScale[24],

    // utility sizes
    'size-full': '100%',
    'size-half': '50%',
    'size-third': '33.333%',
    'size-quarter': '25%',
    'size-fifth': '20%',
    'size-max-fixed': '1152px',
    'size-max-md-fixed': '896px',
    'size-max-sm-fixed': '560px',
    'size-auto': 'auto',
    'size-max-viewport': '100vw',
    ...iconSizes,
  },

  fonts: {
    ...chakraTheme.fonts,
    body: "'Cera PRO', '-apple-system', 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif",
    heading:
      "'Cera PRO', '-apple-system', 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif",
    // A more universal cross-platform monospaced font
    mono: "'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace",
  },

  fontSizes: {
    ...prefixKeys(typographyScale, 'font-'),
    'font-promo': typographyScale['7xl'],
    'font-large': typographyScale.lg,
    'font-body': typographyScale.md,
    'font-small': typographyScale.sm,
    'font-tiny': typographyScale.xs,
    'font-caption': typographyScale.xs,

    'font-h1': typographyScale['4xl'],
    'font-h1-mobile': typographyScale['3xl'],
    'font-h2': typographyScale['3xl'],
    'font-h2-mobile': typographyScale['2xl'],
    'font-h3': typographyScale['2xl'],
    'font-h3-mobile': typographyScale.xl,
    'font-h4': typographyScale.xl,
    'font-h4-mobile': typographyScale.lg,
    'font-h5': typographyScale.lg,
    'font-h5-mobile': typographyScale.md,
    'font-h6': typographyScale.lg,
    'font-h6-mobile': typographyScale.md,
  },

  zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    elevatedContent: 1,
    docked: 2,
    dropdown: 3,
    sticky: 4,
    banner: 4,
    menuOverlay: 5,
    menu: 6,
    overlay: 7,
    modal: 8,
    popover: 9,
    skipLink: 10,
    toast: 11,
    tooltip: 12,
  },

  lineHeights,

  colors: { ...colors, ...foundationColors },

  iconSizes,

  components: {
    ...componentThemes,
  },
} as const
