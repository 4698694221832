import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import makeProductBaseProperties from './product-common';
export function transformLegacyRemoveFromCart(data) {
  const product = data.ecommerce.remove.products[0];
  const cartAffiliation = data.ecommerce.affiliation;
  const currency = data.ecommerce.currencyCode;
  const eventProperties = _objectSpread(_objectSpread({
    category: 'Ecommerce',
    label: 'product_removed',
    cart_id: data.cartId,
    cart_affiliation: cartAffiliation
  }, makeProductBaseProperties(product)), {}, {
    currency,
    quantity: product.quantity
  });
  if (product.category) {
    eventProperties.category_path = product.category;
    const maxCategoryLimit = 5;
    product.category.split(' / ').forEach((category, index) => {
      if (index < maxCategoryLimit && category !== '') {
        const key = "category_".concat(index + 1);
        eventProperties[key] = category;
      }
    });
  }
  return {
    method: 'track',
    parameters: ['Product Removed', eventProperties]
  };
}