import { jwtDecode } from 'jwt-decode';
const DEFAULT_LEEWAY = 60;
export function decodeJWT(jwt) {
  return jwtDecode(jwt);
}

// We don't verify the token, we just decode and check certain fields
export const isInsecurelyValidJWT = function (jwt) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    leeway: DEFAULT_LEEWAY
  };
  try {
    return isInsecurelyValidJWTPayload(decodeJWT(jwt), options);
  } catch (e) {
    return false;
  }
};
export function isInsecurelyValidJWTPayload(payload) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    leeway: DEFAULT_LEEWAY
  };
  const {
    leeway
  } = options;
  const now = new Date();
  if (hasExpired(payload, leeway, now) || hasNotBegun(payload, leeway, now)) {
    return false;
  } else {
    return true;
  }
}
export const hasExpired = (payload, leeway, now) => {
  const expDate = new Date(0);
  if (payload.exp) {
    expDate.setUTCSeconds(payload.exp + leeway);
    return now > expDate;
  } else {
    // We don't accept tokens without an Expiry time
    return true;
  }
};
export const hasNotBegun = (payload, leeway, now) => {
  const nbfDate = new Date(0);
  if (payload.nbf) {
    nbfDate.setUTCSeconds(payload.nbf - leeway);
    return now < nbfDate;
  } else {
    // Nbf is optional
    return false;
  }
};