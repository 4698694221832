export default function makeProductBaseProperties(product) {
  const productProperties = {
    product_id: product.dimension136 || product.dimension137 || '',
    product_variant_id: product.uniqueId || '',
    brand: product.brand || '',
    name: product.name,
    variant: product.variant,
    retailer_id: product.dimension128 || '',
    retailer_name: product.dimension127 || '',
    price: product.price,
    promotions: [...(product.dimension131 && product.dimension132 ? [{
      promotion_type: 'auto',
      promotion_id: product.dimension131,
      promotion_name: product.dimension132
    }] : []), ...(product.dimension133 && product.dimension134 ? [{
      promotion_type: 'coupon',
      promotion_id: product.dimension133,
      promotion_name: product.dimension134
    }] : [])]
  };
  return productProperties;
}