import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { FeatureFlagName } from '../feature-flags';
import useFeatureFlag from './use-feature-flag';
const useSessionLogger = () => {
  const router = useRouter();
  const isEnabled = useFeatureFlag(FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_SESSION_LOGGER);
  useEffect(() => {
    if (isEnabled) {
      console.debug('session', 'page', router === null || router === void 0 ? void 0 : router.pathname);
    }
  }, [isEnabled, router === null || router === void 0 ? void 0 : router.pathname]);
  return useCallback(function () {
    if (isEnabled) {
      for (var _len = arguments.length, messages = new Array(_len), _key = 0; _key < _len; _key++) {
        messages[_key] = arguments[_key];
      }
      console.debug('session', ...messages);
    }
  }, [isEnabled]);
};
export default useSessionLogger;