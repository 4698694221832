var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"website-client@41276"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/website";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'
import { ExtraErrorData } from '@sentry/integrations'
import { isSupportedBrowser } from '@scentregroup/shared/helpers/supported-browsers'

declare global {
  interface Navigator {
    userAgentData?: unknown
  }
}

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV
const PRD_SAMPLE_RATE = 0.01
const tracesSampleRate = process.env.STACK_ENV === 'prd' ? PRD_SAMPLE_RATE : 0

Sentry.init({
  dsn: SENTRY_DSN,
  environment,
  integrations: [
    new ExtraErrorData(),
    new Integrations.Apollo(),
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        /^https:\/\/api\.([^.]+\.)*scentregroup\.io\//,
        /^\//,
      ],
    }),
  ],
  ignoreErrors: [/Non-Error (promise|exception)/],
  tracesSampleRate,
  beforeSend: (event: Sentry.Event) => {
    // Do not send events for unsupported browsers

    // Detect user agent client hints - only very new browsers have this
    const userAgentData = navigator.userAgentData
    if (typeof userAgentData === 'object' && userAgentData !== null) {
      return event
    }

    // Otherwise, just sniff the user agent string like the good old days
    const headers = event?.request?.headers
    const userAgent = headers && headers['User-Agent']
    return isSupportedBrowser(userAgent) ? event : null
  },
  beforeBreadcrumb: (
    breadcrumb: Sentry.Breadcrumb,
    hint?: Sentry.BreadcrumbHint | undefined
  ) => {
    // Do not send breadcrumbs for unsupported browsers

    // Detect user agent client hints - only very new browsers have this
    const userAgentData = navigator.userAgentData
    if (typeof userAgentData === 'object' && userAgentData !== null) {
      return breadcrumb
    }

    // Otherwise, just sniff the user agent string like the good old days
    const headers = hint?.event?.request?.headers
    const userAgent = headers && headers['User-Agent']
    return isSupportedBrowser(userAgent) ? breadcrumb : null
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
