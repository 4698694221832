import { transformLegacyDigitalGiftCardPurchase } from './digital-gift-card';
import { transformLegacyDirectPurchase } from './direct';
export function transformLegacyPurchase(data) {
  if (hasLegacyPurchaseAffiliation(data, 'Westfield - Digital Gift Card - AU')) {
    return transformLegacyDigitalGiftCardPurchase(data);
  } else if (hasLegacyPurchaseAffiliation(data, 'Westfield - Direct - AU')) {
    return transformLegacyDirectPurchase(data);
  } else {
    const _exhaustive = data;
    return _exhaustive;
  }
}
export function hasLegacyPurchaseAffiliation(data, affiliation) {
  return data.ecommerce.affiliation === affiliation;
}