import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import useLocalStorageState from 'use-local-storage-state';
import { isEqual } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { isBrowser } from '../env';
import { evaluateFeatureCollection } from '../lib/remote-feature-management';
import { getFeatureManagementV2 } from '../lib/get-feature-management';
import { useCountry } from '@scentregroup/shared/country';
import { CustomWindowEvents, RawCookieName } from '../constants';
import { useLocalUser } from './use-local-user';
const evaluateWithContext = (rulesets, context, seed) => evaluateFeatureCollection(rulesets, _objectSpread(_objectSpread({
  'time.timestampUTC': moment().valueOf()
}, isBrowser() ? {
  'router.path': window.location.pathname,
  'router.query': window.location.search
} : {}), context), seed);
export const useRemoteFeatureManagement = () => {
  const {
    0: remoteFeatures,
    1: setRemoteFeatures
  } = useState(undefined);
  const [savedEvaluation, setSavedEvaluation] = useLocalStorageState(RawCookieName.REMOTE_FEATURE_MANAGEMENT);
  const apollo = useApolloClient();
  const country = useCountry();
  const user = useLocalUser();
  const userIsLoggedIn = Boolean(user);
  const contextFromHooks = useMemo(() => ({
    'user.region': country,
    'user.loggedIn': "".concat(userIsLoggedIn)
  }), [country, userIsLoggedIn]);
  if (savedEvaluation) {
    const evaluation = evaluateWithContext(savedEvaluation.rulesets, contextFromHooks, savedEvaluation.seed);
    if (!isEqual(remoteFeatures, evaluation)) {
      setRemoteFeatures(evaluation);
    }
  }
  const processNewRulesets = useCallback(rulesets => {
    const savedRulesets = savedEvaluation === null || savedEvaluation === void 0 ? void 0 : savedEvaluation.rulesets;
    if (rulesets && !isEqual(rulesets, savedRulesets)) {
      const evaluation = evaluateWithContext(rulesets, contextFromHooks, savedEvaluation === null || savedEvaluation === void 0 ? void 0 : savedEvaluation.seed);
      const store = {
        seed: evaluation.seed,
        timestamp: moment().valueOf(),
        rulesets
      };
      setSavedEvaluation(store);
    }
  }, [contextFromHooks, savedEvaluation === null || savedEvaluation === void 0 ? void 0 : savedEvaluation.rulesets, savedEvaluation === null || savedEvaluation === void 0 ? void 0 : savedEvaluation.seed, setSavedEvaluation]);
  useEffect(() => {
    const flush = () => {
      if (savedEvaluation) {
        setSavedEvaluation(_objectSpread(_objectSpread({}, savedEvaluation), {}, {
          timestamp: 0
        }));
      }
    };
    window.addEventListener(CustomWindowEvents.REMOTE_FEATURE_MANAGEMENT_INVALIDATE_CACHE, flush);
    return () => {
      window.removeEventListener(CustomWindowEvents.REMOTE_FEATURE_MANAGEMENT_INVALIDATE_CACHE, flush);
    };
  }, [savedEvaluation, setSavedEvaluation]);
  useEffect(() => {
    if (savedEvaluation !== null && savedEvaluation !== void 0 && savedEvaluation.timestamp && !moment(savedEvaluation === null || savedEvaluation === void 0 ? void 0 : savedEvaluation.timestamp).isBefore(
    // Based on about half the average session duration
    // eslint-disable-next-line no-magic-numbers
    moment().subtract(1, 'minute'))) {
      return;
    }
    getFeatureManagementV2(apollo, 'WestfieldDirect').then(processNewRulesets).catch(e => {
      console.error(e);
    });
    // While we do use the saved rulesets as a dependancy we don't want to update
    // when they update as they're defined in here anyway
  }, [savedEvaluation === null || savedEvaluation === void 0 ? void 0 : savedEvaluation.timestamp, savedEvaluation === null || savedEvaluation === void 0 ? void 0 : savedEvaluation.seed, setSavedEvaluation, apollo, processNewRulesets]);
  return remoteFeatures;
};