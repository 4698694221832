import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// From: https://github.com/vercel/next.js/issues/4804#issuecomment-606624325
import { useRouter as useNextRouter } from 'next/router';
import React from 'react';

/**
 * Given a string such as:
 *
 * https://example.com/foo?bar=zip&name=Sam
 *
 * Will return:
 *
 * {
 *   bar: 'zip',
 *   name: 'Sam',
 * }
 */
const queryFromUrl = url => {
  const [, ...queryStrings] = url.split('?');
  const queryString = queryStrings.join('?');
  const query = {};
  for (const [key, value] of new URLSearchParams(queryString).entries()) {
    query[key] = value;
  }
  return query;
};
const extractQueryFromRouter = router => _objectSpread(_objectSpread({}, queryFromUrl(router.asPath)), router.query);

/**
 * Provide a router hook which ensures router.query is always correctly hydrated
 * on the first render even when statically optimised to avoid [this caveat from
 * the docs](https://nextjs.org/docs/routing/dynamic-routes):
 *
 * > Pages that are statically optimized by Automatic Static Optimization will
 * > be hydrated without their route parameters provided, i.e `query` will be an
 * > empty object (`{}`).
 *
 * Usage is identical to `import { useRouter } from 'next/router';
 */
export const useRouter = () => {
  const router = useNextRouter();
  router.query = extractQueryFromRouter(router);
  return router;
};
export const useIsRouteChanging = () => {
  const router = useRouter();
  const [isRouteChanging, setRouteChanging] = React.useState(false);
  const routeChangeStart = React.useCallback((_url, _ref) => {
    let {
      shallow
    } = _ref;
    if (!shallow) {
      setRouteChanging(true);
    }
  }, [setRouteChanging]);
  const routeChangeCompleteOrError = React.useCallback(() => {
    setRouteChanging(false);
  }, [setRouteChanging]);
  React.useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeCompleteOrError);
    router.events.on('routeChangeError', routeChangeCompleteOrError);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeCompleteOrError);
      router.events.off('routeChangeError', routeChangeCompleteOrError);
    };
  }, [router, routeChangeStart, routeChangeCompleteOrError]);
  return isRouteChanging;
};