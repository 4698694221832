import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable @typescript-eslint/no-explicit-any */

// Note I don't think we can get away from explicit any in this kind of
// very general dynamic object walking shenanigans, however hopefully
// filter nodes is still reasonably typesafe (so long as its not itself buggy)
// given we constrain the return type match properly against the input type

import pickBy from 'lodash/pickBy';
import isObjectLike from 'lodash/isObjectLike';
import mapValues from 'lodash/mapValues';
import { isDefinedAndNotNull } from '../lib';

// N.B. Returning Partial<InputType> is not enough because we recursively
// filter the whole object tree; so we need a recursive version of Partial
// N.B. you can sometimes define types recursively in aliases, but sometimes not
// https://github.com/Microsoft/TypeScript/issues/12525#issuecomment-263166239
// The following is apparently the standard trick for working around that:
// https://github.com/microsoft/TypeScript/issues/6230
// eslint-disable-next-line @typescript-eslint/no-empty-interface
// Given an object/array, recursively remove nodes in the object tree
// at given paths for which predicate returns falsy
export function filterNodes(toBeFiltered, predicate) {
  const seen = new Set();
  function filterNodes_(item, path) {
    if (seen.has(item) || !isObjectLike(item)) {
      return item;
    }
    seen.add(item);
    // N.B. The creation of large numbers of [...path, key] is likely super
    // inefficient. Rewrite if requiring performance over a large structure
    const guard = (x, key) => predicate(x, [...path, key]);
    const recurse = (x, key) => filterNodes_(x, [...path, key]);
    return Array.isArray(item) ? item.filter(guard).map(recurse) : mapValues(pickBy(item, guard), recurse);
  }
  return filterNodes_(toBeFiltered, []);
}
export function filterNullsFromItems(graphqlV2Response) {
  return filterNodes(graphqlV2Response, function testForNulls(elem, path) {
    const len = path.length;
    const GRANDPARENT = 2;
    const isItemExcluded = len >= GRANDPARENT && path[len - GRANDPARENT] === 'items' && elem === null;
    return !isItemExcluded;
  });
}
export function transformUserData(data) {
  var _userData$firstName, _userData$lastName;
  if (!(data !== null && data !== void 0 && data.currentUser)) {
    return undefined;
  }
  const userData = data.currentUser;
  return _objectSpread(_objectSpread({}, userData), {}, {
    firstName: (_userData$firstName = userData.firstName) !== null && _userData$firstName !== void 0 ? _userData$firstName : '',
    lastName: (_userData$lastName = userData.lastName) !== null && _userData$lastName !== void 0 ? _userData$lastName : '',
    storedPaymentMethods: userData.storedPaymentMethods ? userData.storedPaymentMethods.filter(isDefinedAndNotNull) : [],
    storedAddresses: userData.storedAddresses ? userData.storedAddresses.filter(isDefinedAndNotNull) : []
  });
}