import { HttpLink } from '@apollo/client';
import { abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill';
export function makeHttpLink(_ref) {
  let {
    defaultVersion,
    urlsByVersion
  } = _ref;
  return new HttpLink({
    fetch: abortableFetch(fetch).fetch,
    uri(operation) {
      const version = operation.getContext().version || defaultVersion;
      const url = urlsByVersion[version];
      if (!url) {
        throw new Error("no url for version ".concat(version));
      }
      return url;
    }
  });
}