export const slugify = str => str.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '').replace(/-{2,}/g, '-');
export const nthSuffix = d => {
  /* eslint-disable no-magic-numbers */
  if (d > 3 && d < 21) {
    return 'th';
  }
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
  /* eslint-enable */
};

export const pluralise = (val, string) => "".concat(string).concat(val === 1 ? '' : 's');
export const arrayToObject = array => array.reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});
export const throttle = (cb, limit) => {
  /* eslint-disable init-declarations */
  let lastExecuted;
  let timeout;
  /* eslint-enable init-declarations */
  return () => {
    const now = Date.now();
    if (lastExecuted && now < lastExecuted + limit) {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        lastExecuted = now;
        cb();
      }, limit);
    } else {
      lastExecuted = now;
      cb();
    }
  };
};
export const makeMailUrl = email => "mailto:".concat(email);
export const isEmpty = obj => Object.keys(Object(obj)).length === 0;
export const capitalize = str => "".concat(str[0].toUpperCase()).concat(str.slice(1));