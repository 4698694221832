import { useCookies } from 'react-cookie';
import { getSegmentConfiguration } from '../config';
import { RawCookieName } from '../constants';
export function useSegmentConfiguration(country) {
  const [cookies] = useCookies([RawCookieName.SEGMENT_WRITE_KEY]);
  const writeKeyFromCookie = cookies[RawCookieName.SEGMENT_WRITE_KEY];
  const {
    cdnURL,
    writeKey: writeKeyFromConfiguration,
    writeKeyOverridable
  } = getSegmentConfiguration(country);
  let writeKey = writeKeyFromConfiguration;
  if (writeKeyOverridable && writeKeyFromCookie) {
    writeKey = writeKeyFromCookie;
  }
  if (!writeKey) {
    return null;
  }
  return {
    cdnURL,
    writeKey
  };
}