import { ApolloLink } from '@apollo/client';
import { ErrorLink } from '@apollo/client/link/error';
export function makeOperationLoggingLink() {
  return ApolloLink.from([(operation, forward) => {
    console.log("GraphQL Operation: ".concat(operation.operationName));
    return forward(operation);
  }, new ErrorLink(_ref => {
    let {
      operation,
      graphQLErrors,
      networkError
    } = _ref;
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        console.error('GraphQL Error:', {
          operationName: operation.operationName,
          message: error.message,
          variables: operation.variables
        });
      }
    }
    if (networkError) {
      console.error('Network Error:', {
        operationName: operation.operationName,
        message: networkError.message,
        variables: operation.variables
      });
    }
  })]);
}