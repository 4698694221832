import { useEffect, useState } from 'react';
const useIsMounted = () => {
  const {
    0: isMounted,
    1: setIsMounted
  } = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);
  return isMounted;
};
export default useIsMounted;