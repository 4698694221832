import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { RawCookieName } from '../constants';
import { decodeIdToken } from '../helpers/id-token';
function getLocalUserFromIdToken(idToken) {
  if (!idToken) {
    return undefined;
  }
  try {
    const {
      scentregroupId,
      email,
      firstName,
      lastName,
      emailVerified,
      emailPending
    } = decodeIdToken(idToken);
    return scentregroupId ? {
      isLoggedIn: Boolean(scentregroupId),
      scentregroupId,
      email,
      firstName,
      lastName,
      emailVerified,
      emailPending
    } : undefined;
  } catch (e) {
    return undefined;
  }
}
export function getLocalUserFromRequestCookies(cookies) {
  const idTokenCookieValue = cookies[RawCookieName.ID_TOKEN];
  return idTokenCookieValue ? getLocalUserFromIdToken(idTokenCookieValue) : undefined;
}
export function useLocalUser() {
  const [cookies] = useCookies([RawCookieName.ID_TOKEN]);
  const idTokenCookieValue = cookies[RawCookieName.ID_TOKEN];
  return useMemo(() => getLocalUserFromIdToken(idTokenCookieValue), [idTokenCookieValue]);
}