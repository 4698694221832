import { RetryLink } from '@apollo/client/link/retry';
export function makeRetryLink() {
  return new RetryLink({
    delay: {
      initial: 100,
      max: 2000,
      jitter: true
    },
    attempts(count, operation, _error) {
      const operationContext = operation.getContext();
      if (operationContext['retryLimit']) {
        return count < operationContext['retryLimit'];
      }
      return false;
    }
  });
}