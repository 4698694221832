import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ApolloLink } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
function isCookieEnabledBrowser() {
  return typeof navigator === 'object' && navigator.cookieEnabled === true;
}
export function makeDefaultHeadersLink() {
  let {
    requestId,
    getSessionId,
    getAnonymousId
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new ApolloLink((operation, forward) => {
    operation.setContext(_ref => {
      let {
        version,
        headers
      } = _ref;
      const defaultHeaders = {
        'x-request-id': requestId || uuidv4()
      };

      // eslint-disable-next-line no-magic-numbers
      if (getSessionId && version === 2) {
        const sessionId = getSessionId();
        if (sessionId) {
          defaultHeaders['X-Scg-Session-Id'] = sessionId;
        }
      }
      if (getAnonymousId && version === 2) {
        const anonymousId = getAnonymousId();
        if (anonymousId) {
          defaultHeaders['X-Segment-Anonymous-Id'] = anonymousId;
        }
      }
      if (isCookieEnabledBrowser()) {
        const tabId = window.sessionStorage.getItem('tabId');
        if (tabId) {
          defaultHeaders['X-Tab-Session-Id'] = tabId;
        }
      }
      return {
        headers: _objectSpread(_objectSpread({}, headers), defaultHeaders)
      };
    });
    return forward(operation);
  });
}