import { supportedBrowsersRegExp } from './reg-exp';

/*
The regular expression imported above is generated from the "browserslist"
property in the root `package.json` file. We use the same property to drive
polyfilling of generated browser bundles in each app, so the regular expression
should always match.

To regenerate the imported regular expression:
  1. Go to project root
  2. Update the "browserslist" property in `./package.json` as desired
  3. Update the `reg-exp.ts` file by executing:
        yarn run updateSupportedBrowsers

We generate this regular expression statically so that we can do browser
matching on the client side, where the browserslist functionality is not
available.

See: https://github.com/browserslist/browserslist-useragent-regexp
*/

export const isSupportedBrowser = userAgent => {
  // If there is no test, assume everything is supported
  if (!supportedBrowsersRegExp) {
    return true;
  }
  if (!(supportedBrowsersRegExp instanceof RegExp)) {
    return true;
  }

  // Assume a missing user agent string is an unsupported browser
  if (!userAgent) {
    return false;
  }

  // Test the user agent against the regular expression
  return supportedBrowsersRegExp.test(userAgent);
};