import { useContext, useEffect, useState } from 'react';
import { getClientConfigBool } from '../config';
import { FeatureFlagsContext } from '../context';
let isInitialMount = true;
function useFeatureFlag(featureFlagName) {
  const featureFlags = useContext(FeatureFlagsContext);
  const {
    0: isMounted,
    1: setIsMounted
  } = useState(() => !isInitialMount);
  useEffect(() => {
    setIsMounted(true);
    isInitialMount = false;
  }, []);
  // This is a hack but until we can deal with hydration issues
  // better generally we probably need such hacks:
  const isTest = false;
  if (isMounted || isTest) {
    return featureFlags[featureFlagName];
  } else {
    // prevent 'Hydration failed because the initial UI does not match what was rendered on the server'
    return getClientConfigBool(featureFlagName);
  }
}
export default useFeatureFlag;