export function transformObjectActionEvent(event) {
  if (event.legacy) {
    return event.legacy;
  }
  switch (event.object) {
    case 'test':
      return [];
    case 'Cart':
    case 'Centre':
    case 'Checkout':
    case 'Checkout Step':
    case 'Global':
    case 'Order':
    case 'Product':
    case 'Product List':
    case 'Content':
    case 'Navigation':
    case 'Content Impression':
    case 'Module':
      return [];
    default:
      {
        const _exhaustive = event;
      }
  }
  return [];
}