import { InMemoryCache, ApolloClient, ApolloLink } from '@apollo/client';
import { isDefinedAndNotNull } from '../lib';
import { makeAuthenticationTokenLink, makeAuthenticationResetLink, makeAuthorizationLink } from './links/auth';
import { makeDefaultHeadersLink } from './links/default-headers';
import { makeHttpLink } from './links/http';
import { makeOperationLoggingLink } from './links/logging';
import { makeRetryLink } from './links/retry';
import typePolicies from './type-policies';
export function makeApolloClient(_ref) {
  let {
    client,
    operationLogging,
    defaultVersion,
    urlsByVersion,
    requestId,
    auth0,
    cookies,
    getSessionId,
    getAnonymousId
  } = _ref;
  const link = ApolloLink.from([operationLogging ? makeOperationLoggingLink() : null, makeDefaultHeadersLink({
    requestId,
    getSessionId,
    getAnonymousId
  }), makeAuthenticationTokenLink({
    auth0,
    cookies
  }), makeAuthenticationResetLink({
    auth0,
    cookies
  }), makeAuthorizationLink(), makeRetryLink(), makeHttpLink({
    urlsByVersion,
    defaultVersion
  })].filter(isDefinedAndNotNull));
  const cache = new InMemoryCache({
    typePolicies
  });
  return new ApolloClient({
    ssrMode: false,
    link,
    cache,
    name: client.name,
    version: client.version,
    defaultOptions: {
      query: {
        errorPolicy: 'all'
      },
      watchQuery: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
}