import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { affiliations } from '../../../helpers/analytics-utils/constants';
import { transformObjectActionEventForSegment } from '..';
import makeProductBaseProperties from '../product-common';
const decimalPlaces = 2;
export function transformLegacyDirectPurchase(data) {
  const cartAffiliation = affiliations.WESTFIELD_DIRECT_AU;
  const currency = data.ecommerce.currencyCode;
  const cartProducts = data.ecommerce.purchase.products.map(product => {
    const cartProduct = _objectSpread(_objectSpread({}, makeProductBaseProperties(product)), {}, {
      currency,
      quantity: product.quantity
    });
    if (product.category) {
      cartProduct.category_path = product.category;
      const maxCategoryLimit = 5;
      product.category.split(' / ').forEach((category, index) => {
        if (index < maxCategoryLimit && category !== '') {
          const key = "category_".concat(index + 1);
          cartProduct[key] = category;
        }
      });
    }
    return cartProduct;
  });
  const {
    billingAddress,
    shippingAddress
  } = data.ecommerce.purchase;
  const shipping = Number(data.ecommerce.purchase.actionField.shipping);
  const total = Number(data.ecommerce.purchase.actionField.revenue);
  const tax = Number(data.ecommerce.purchase.actionField.tax);
  return transformObjectActionEventForSegment({
    object: 'Order',
    action: 'Completed',
    properties: {
      category: 'Ecommerce',
      label: 'order_completed',
      cart_id: data.cartId ? data.cartId : '',
      cart_affiliation: cartAffiliation,
      currency,
      products: cartProducts,
      total,
      revenue: Number(Number(total - shipping - tax).toFixed(decimalPlaces)),
      tax,
      order_id: data.ecommerce.purchase.actionField.id,
      shipping,
      shipping_address: shippingAddress ? {
        suburb: shippingAddress.suburb,
        postcode: shippingAddress.postCode,
        state_territory: shippingAddress.state,
        country: shippingAddress.country || 'AU'
      } : undefined,
      billing_address: {
        suburb: billingAddress.suburb,
        postcode: billingAddress.postCode,
        state_territory: billingAddress.state,
        country: billingAddress.country
      },
      collect_location: data.ecommerce.purchase.deliveryType === 'CLICK_AND_COLLECT' ? data.ecommerce.purchase.collectLocation : '',
      delivery_type: data.ecommerce.purchase.deliveryType,
      payment_method: "credit card - ".concat(data.ecommerce.purchase.paymentMethod)
    }
  });
}