import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { MarketplaceApplyCouponCodeDocument } from '..';
export function useMarketplaceApplyCouponCode() {
  const [marketplaceApplyCouponCode] = useMutation(MarketplaceApplyCouponCodeDocument, {
    context: {
      version: 2
    }
  });
  return useCallback(async (couponCode, cb) => {
    var _data$coupon;
    const {
      data
    } = await marketplaceApplyCouponCode({
      variables: {
        couponCode
      }
    });
    cb((_data$coupon = data === null || data === void 0 ? void 0 : data.coupon) !== null && _data$coupon !== void 0 ? _data$coupon : null);
  }, [marketplaceApplyCouponCode]);
}