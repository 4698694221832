import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React, { Fragment, useCallback } from 'react'
import Head from 'next/head'
import { Auth0Provider } from '@auth0/auth0-react'
import { Link } from '@scentregroup/shared/link'
import '@scentregroup/ui/fonts/index.css'
import '@scentregroup/ui/tools/reset.css'
import FeatureFlagsProvider, {
  useFeatureFlagOverridesFromQuery,
} from '@scentregroup/shared/components/feature-flags-provider'
import SkipToContentLink from '@scentregroup/shared/components/skip-to-content-link'
import {
  GetHrefContext,
  AgentInfoContext,
  BasicPageContext,
  LinkContext,
} from '@scentregroup/shared/context'
import UserStateProvider from '@scentregroup/shared/components/user-state-provider'
import {
  SessionIdProvider,
  CookieSessionIdStorage,
} from '@scentregroup/shared/session-id'
import { agentInfo } from '@scentregroup/shared/helpers/get-browser'
import { NATIONAL_CONFIG } from '@scentregroup/shared/config'
import { RawCookieName } from '@scentregroup/shared/constants'
import RouteChangeIndicator from '@scentregroup/shared/hub-components/route-change-indicator'
import { Spinner } from '@scentregroup/ui'
import CouponCodeDetector from '@scentregroup/shared/components/coupon-code-detector'
import { HubProvider } from '@hub/design-system-base'
import GoogleTagManager, {
  GoogleTagManagerData,
} from '@scentregroup/shared/hub-components/google-tag-manager'
import useIsMounted from '@scentregroup/shared/hooks/use-is-mounted'
import { useRouter } from '@scentregroup/shared/next/router'
import { ApolloProvider } from '../components/apollo-provider'
import NextQueryParamsProvider from '../lib/next-query-params-provider'
import { MagicLinkProvider } from '@scentregroup/shared/hub-components/magic-link'
import { AnalyticsProvidersAndInit } from '@scentregroup/shared/analytics'
import { CountryProvider } from '@scentregroup/shared/country'

export interface IBasePageProps {
  isBasicPage?: boolean
}

export type IAppPageProps<P = unknown> = IBasePageProps & P

interface IAppProps<P> {
  Component: React.FunctionComponent<
    React.PropsWithChildren<
      P & {
        err?: any
      }
    >
  >
  pageProps: IAppPageProps<P>
  err?: any
}

const getAgent = (): string =>
  (typeof navigator !== 'undefined' && navigator.userAgent) || ''

const cookieSessionIdStorage = new CookieSessionIdStorage({
  cookieName: RawCookieName.SESSION_ID,
  expiryInDays: 8,
})

const App: React.FC<React.PropsWithChildren<IAppProps<unknown>>> = function ({
  Component,
  pageProps: { isBasicPage, ...pageProps },
  err,
}) {
  const { locale, asPath, query } = useRouter()

  const isBrowser = useIsMounted()

  const META_INFORMATION =
    locale === 'en-NZ' ? NATIONAL_CONFIG.nz : NATIONAL_CONFIG.au

  const getHref = useCallback(
    () =>
      (isBrowser && window.location.href) || `${META_INFORMATION.url}${asPath}`,
    [META_INFORMATION, asPath, isBrowser]
  )

  const featureFlagOverrides = useFeatureFlagOverridesFromQuery(query)

  return (
    <Fragment>
      <Head>
        <link rel="preconnect" href="https://images.scentregroup.io" />
        {query.q && <meta key="meta-robots" name="robots" content="noindex" />}
        <title>{META_INFORMATION.title}</title>
        {META_INFORMATION.meta.map(({ name, content }) => (
          <meta key={name} name={name} content={content} />
        ))}
      </Head>
      <GoogleTagManager
        containerId={process.env.NEXT_PUBLIC_GTM_ID}
        preview={process.env.NEXT_PUBLIC_GTM_PREVIEW_ENV}
        auth={process.env.NEXT_PUBLIC_GTM_PREVIEW_AUTH}
      />
      <GoogleTagManagerData />
      <LinkContext.Provider value={Link}>
        <HubProvider Link={Link}>
          <SessionIdProvider storage={cookieSessionIdStorage}>
            <Auth0Provider
              domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? ''}
              clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? ''}
              authorizationParams={{
                redirect_uri: isBrowser
                  ? `${window.location.origin}/account/callback`
                  : undefined,
              }}
            >
              <ApolloProvider>
                <CountryProvider country={META_INFORMATION.country}>
                  <FeatureFlagsProvider
                    doGetFromEnv
                    overrides={featureFlagOverrides}
                  >
                    <>
                      <GetHrefContext.Provider value={getHref}>
                        <UserStateProvider>
                          <AgentInfoContext.Provider
                            value={agentInfo(getAgent())}
                          >
                            <AnalyticsProvidersAndInit>
                              <NextQueryParamsProvider>
                                <BasicPageContext.Provider
                                  value={Boolean(isBasicPage)}
                                >
                                  <RouteChangeIndicator
                                    fallback={() => (
                                      <div
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                        }}
                                      >
                                        <Spinner
                                          type="large"
                                          isSeasonal={false}
                                        />
                                      </div>
                                    )}
                                  >
                                    <SkipToContentLink skipToId="main-content" />
                                    <MagicLinkProvider>
                                      {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                                      <Component {...pageProps} err={err} />
                                    </MagicLinkProvider>
                                  </RouteChangeIndicator>
                                </BasicPageContext.Provider>
                              </NextQueryParamsProvider>
                            </AnalyticsProvidersAndInit>
                          </AgentInfoContext.Provider>
                        </UserStateProvider>
                      </GetHrefContext.Provider>
                      <CouponCodeDetector />
                    </>
                  </FeatureFlagsProvider>
                </CountryProvider>
              </ApolloProvider>
            </Auth0Provider>
          </SessionIdProvider>
        </HubProvider>
      </LinkContext.Provider>
    </Fragment>
  )
}

export default App
