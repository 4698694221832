const apolloClientTypePolicies = {
  Cart: {
    fields: {
      items: {
        merge: false
      }
    }
  },
  Customer: {
    keyFields: ['scentregroupId']
  },
  GiftCardCartItem: {
    fields: {
      recipientDetails: {
        merge: false
      }
    }
  },
  MarketplaceProductFacet: {
    fields: {
      options: {
        merge: false,
        read(_, _ref) {
          let {
            args,
            toReference
          } = _ref;
          return toReference({
            __typename: 'MarketplaceProductFacet',
            search: args === null || args === void 0 ? void 0 : args.search
          });
        }
      }
    }
  },
  MarketplaceProductFacetOption: {
    merge: false
  }
};
export default apolloClientTypePolicies;