import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAuth0 } from '@auth0/auth0-react';
import { isInsecurelyValidJWT } from '../helpers/jwt';
import useSession from '../hooks/use-session';
import { RawCookieName } from '../constants';
const noop = () => undefined;
const {
  ID_TOKEN,
  SCENTREGROUP_ID
} = RawCookieName;
function useIdToken() {
  const [cookies] = useCookies([ID_TOKEN, SCENTREGROUP_ID]);
  const idTokenFromCookie = cookies[ID_TOKEN];
  const {
    0: idToken,
    1: setIdToken
  } = useState(isInsecurelyValidJWT(idTokenFromCookie) ? idTokenFromCookie : null);
  const {
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading,
    isAuthenticated
  } = useAuth0();
  const {
    setSession
  } = useSession();
  useEffect(() => {
    if (isLoading) {
      return;
    }

    // Only attempt a silent auth if auth0 thinks we're authenticated.
    // Otherwise we are checking silent auth on every page load, even
    // when there is no indication of the user being logged in.
    if (!isAuthenticated) {
      return;
    }
    if (!idToken || !isInsecurelyValidJWT(idToken)) {
      getAccessTokenSilently().then(() => getIdTokenClaims()).then(claims => {
        // Save the fresh ID token for future use
        if (claims) {
          const idToken = claims.__raw;
          setIdToken(idToken);
          setSession(idToken);
        }
        return null;
      }).catch(noop);
    }
  }, [getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading, idToken, setSession]);
  if (idTokenFromCookie) {
    // If there is a token and is kind of valid, just use it.
    // The API will reject it if it is not actually valid.
    if (isInsecurelyValidJWT(idTokenFromCookie)) {
      return {
        idToken: idTokenFromCookie
      };
    }
  }
  return {
    idToken
  };
}
export default useIdToken;