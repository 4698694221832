import { GetFeatureManagementCollectionDocument, GetFeatureManagementCollectionV2Document } from '../graphql';
export async function getFeatureMangement(apollo, scope, featureSlug) {
  var _data$cfFeatureManage, _data$cfFeatureManage2;
  const {
    data
  } = await apollo.query({
    context: {
      version: 2
    },
    query: GetFeatureManagementCollectionDocument,
    variables: {
      scope,
      featureSlug
    }
  });
  if (!(data !== null && data !== void 0 && (_data$cfFeatureManage = data.cfFeatureManagementCollection) !== null && _data$cfFeatureManage !== void 0 && _data$cfFeatureManage.items)) {
    return null;
  }
  return data === null || data === void 0 || (_data$cfFeatureManage2 = data.cfFeatureManagementCollection) === null || _data$cfFeatureManage2 === void 0 ? void 0 : _data$cfFeatureManage2.items;
}
export async function getFeatureManagementV2(apollo, scope) {
  const {
    data
  } = await apollo.query({
    context: {
      version: 2
    },
    query: GetFeatureManagementCollectionV2Document,
    variables: {
      scope
    }
  });
  return queriedFeatureManagementCollectionToFeatureRuleSet(data.featureManagementFeatures);
}
function queriedFeatureManagementCollectionToFeatureRuleSet(queriedCollection) {
  return queriedCollection.flatMap(record => record ? {
    featureSlug: record.featureSlug,
    type: record.type,
    defaultResult: record.defaultResult,
    rules: record.rules.flatMap(recordRule => recordRule ? {
      condition: recordRule.condition,
      results: recordRule.results.flatMap(recordRuleResult => {
        var _recordRuleResult$coh;
        return recordRuleResult ? {
          cohortName: (_recordRuleResult$coh = recordRuleResult.cohortName) !== null && _recordRuleResult$coh !== void 0 ? _recordRuleResult$coh : '',
          distribution: recordRuleResult.distribution,
          result: recordRuleResult.result
        } : [];
      })
    } : [])
  } : []);
}