import { transformLegacyAddToCart } from './add-to-cart';
import { transformLegacyViewItem } from './view-item';
import { transformLegacyRemoveFromCart } from './remove-from-cart';
import { transformLegacySendHitCheckout } from './sendhit-checkout';
import { transformLegacyPurchase } from './purchase';
import { transformLegacySearchInteractionCentreSearch } from './search-interaction';
import { isDGCEvent } from '../../analytics/types/events/legacy';
export function transformEventForSegment(event) {
  if ('type' in event) {
    switch (event.type) {
      case 'test':
        return null;
      case 'legacy':
        return transformLegacyEventForSegment(event.data);
      default:
        {
          const _exhaustive = event;
          return _exhaustive;
        }
    }
  }
  return transformObjectActionEventForSegment(event);
}
export function transformLegacyEventForSegment(data) {
  switch (data.event) {
    case 'view_item':
      {
        if (isDGCEvent(data)) {
          return null;
        }
        return transformLegacyViewItem(data);
      }
    case 'product_list_viewed':
      {
        // was a marketplace event
        return null;
      }
    case 'add_to_cart':
      {
        if (isDGCEvent(data)) {
          return null;
        }
        return transformLegacyAddToCart(data);
      }
    case 'remove_from_cart':
      {
        if (isDGCEvent(data)) {
          return null;
        }
        return transformLegacyRemoveFromCart(data);
      }
    case 'view_cart':
      {
        // currently only a DGC event
        return null;
      }
    case 'begin_checkout':
      {
        // currently only a DGC event
        return null;
      }
    case 'add_payment_info':
      {
        // currently only a DGC event
        return null;
      }
    case 'sendhitCheckout':
      {
        return transformLegacySendHitCheckout(data);
      }
    case 'purchase':
      {
        return transformLegacyPurchase(data);
      }
    case 'user_interaction':
      {
        return null;
      }
    case 'SearchInteraction':
      {
        switch (data.eventCategory) {
          case 'centreSearch':
            return transformLegacySearchInteractionCentreSearch(data);
          default:
            {
              const _exhaustive = data;
              return _exhaustive;
            }
        }
      }
    default:
      {
        const _exhaustive = data;
        return _exhaustive;
      }
  }
}
export function transformObjectActionEventForSegment(event) {
  return {
    method: 'track',
    parameters: ["".concat(event.object, " ").concat(event.action), event.properties]
  };
}