import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'universal-cookie';
import { setContext } from '@apollo/client/link/context';
import { isInsecurelyValidJWT } from '../../helpers/jwt';
import { setSession } from '../../hooks/use-session';
import { RawCookieName } from '../../constants';
export const makeAuthenticationTokenLink = _ref => {
  let {
    auth0,
    cookies
  } = _ref;
  const cookieManager = cookies !== null && cookies !== void 0 ? cookies : new Cookies();
  return setContext(async (_, prevContext) => {
    if (!auth0) {
      return prevContext;
    }
    const idTokenFromCookie = cookieManager.get(RawCookieName.ID_TOKEN, {
      doNotParse: true
    });

    // Check the exp and nbf timestamps inside the JWT. We do not want a leeway
    // (defaults to 60s) as if we use the idToken after expiry, it will 401 and
    // log us out.
    if (idTokenFromCookie && isInsecurelyValidJWT(idTokenFromCookie, {
      leeway: 0
    })) {
      return _objectSpread(_objectSpread({}, prevContext), {}, {
        idToken: idTokenFromCookie
      });
    }

    // Bail out completely if there is no idToken; otherwise, we would check
    // against Auth0 before every request while logged out, which is costly.
    //
    // You can opt-in to checking the session by passing `checkForSession: true`
    // to the context.
    if (!idTokenFromCookie && !prevContext.checkForSession) {
      return prevContext;
    }
    try {
      // Since we instantiate the client on its own (ie. with
      // `new Auth0Client`), we need to first check the session.
      // Without this, `getIdTokenClaims` fails silently.
      // See https://github.com/auth0/auth0-spa-js#creating-the-client
      await auth0.getAccessTokenSilently();
      const claims = await auth0.getIdTokenClaims();
      if (claims === undefined) {
        return prevContext;
      }
      const idToken = claims.__raw;

      // Save the fresh ID token for future use
      setSession(cookieManager.set.bind(cookieManager), idToken);
      return _objectSpread(_objectSpread({}, prevContext), {}, {
        idToken
      });
    } catch (err) {
      return prevContext;
    }
  });
};
export const makeAuthenticationResetLink = _ref2 => {
  let {
    cookies
  } = _ref2;
  const cookieManager = cookies !== null && cookies !== void 0 ? cookies : new Cookies();
  return onError(_ref3 => {
    let {
      graphQLErrors
    } = _ref3;
    if (graphQLErrors !== null && graphQLErrors !== void 0 && graphQLErrors.some(e => e.message === 'Unauthenticated')) {
      cookieManager.remove(RawCookieName.ID_TOKEN, {
        path: '/'
      });
    }
  });
};
export const makeAuthorizationLink = () => new ApolloLink((operation, forward) => {
  const idToken = operation.getContext().idToken;
  operation.setContext(_ref4 => {
    let {
      headers
    } = _ref4;
    return {
      headers: _objectSpread(_objectSpread({}, headers), idToken && isInsecurelyValidJWT(idToken) && {
        Authorization: "Bearer ".concat(idToken)
      })
    };
  });
  return forward(operation);
});