import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { affiliations } from '../../helpers/analytics-utils/constants';
import makeProductBaseProperties from './product-common';
import { transformObjectActionEventForSegment } from '.';
const decimalPlaces = 2;
export function transformLegacySendHitCheckout(data) {
  const cartAffiliation = affiliations.WESTFIELD_DIRECT_AU;
  const currency = data.ecommerce.currencyCode;

  // NOTE: product information will be the same for 'cart_viewed, fullfillment, payment'
  const cartProducts = data.ecommerce.checkout.products.map(product => {
    const cartProduct = _objectSpread(_objectSpread({}, makeProductBaseProperties(product)), {}, {
      currency,
      quantity: product.quantity
    });
    if (product.category) {
      cartProduct.category_path = product.category;
      const maxCategoryLimit = 5;
      product.category.split(' / ').forEach((category, index) => {
        if (index < maxCategoryLimit && category !== '') {
          const key = "category_".concat(index + 1);
          cartProduct[key] = category;
        }
      });
    }
    return cartProduct;
  });
  const totalValue = Number(cartProducts.reduce((total, product) => {
    var _product$price;
    return total + ((_product$price = product.price) !== null && _product$price !== void 0 ? _product$price : 0) * product.quantity;
  }, 0).toFixed(decimalPlaces));
  if (data.ecommerce.checkout.actionField.step === '1') {
    return transformObjectActionEventForSegment({
      object: 'Cart',
      action: 'Viewed',
      properties: {
        category: 'Ecommerce',
        label: 'cart_viewed',
        cart_id: data.cartId,
        cart_affiliation: cartAffiliation,
        products: cartProducts,
        value: totalValue,
        currency,
        tax: undefined
      }
    });
  }
  return transformObjectActionEventForSegment({
    object: 'Checkout Step',
    action: 'Viewed',
    properties: {
      category: 'Ecommerce',
      label: 'checkout_step_viewed',
      cart_id: data.cartId,
      cart_affiliation: cartAffiliation,
      products: cartProducts,
      value: totalValue,
      currency,
      tax: undefined
    }
  });
}