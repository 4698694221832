export function transformLegacySearchInteractionCentreSearch(data) {
  const eventProperties = {
    category: 'Search',
    label: 'centre_searched',
    keyword: data.centreResultClicked
  };
  return {
    method: 'track',
    parameters: ['Centre Searched', eventProperties]
  };
}