import { getEnvBool } from './config/get-env';
import { makeCountrySpecificValue, resolveCountrySpecificValue } from './configuration';
import { FeatureFlagName } from './feature-flags';
import { arrayToObject } from './helpers';

// WARNING! Don't put anything here that you do not want to be exposed to the client.
export const CONFIG = ['NEXT_PUBLIC_ASSET_HOST', 'NEXT_PUBLIC_AU_HOST', 'NEXT_PUBLIC_NZ_HOST', 'NEXT_PUBLIC_CONTENT_SERVICE', 'NEXT_PUBLIC_CONTENT_SERVICE_V2', 'NEXT_PUBLIC_ALGOLIA_APPLICATION_ID', 'NEXT_PUBLIC_ALGOLIA_SEARCH_KEY', 'NEXT_PUBLIC_MKP_ALGOLIA_APPLICATION_ID', 'NEXT_PUBLIC_MKP_ALGOLIA_SEARCH_KEY', 'NEXT_PUBLIC_SENTRY_DSN', 'NEXT_PUBLIC_SENTRY_ENV', 'NEXT_PUBLIC_AUTH0_DOMAIN', 'NEXT_PUBLIC_AUTH0_CLIENT_ID', 'NEXT_PUBLIC_GTM_ID', 'NEXT_PUBLIC_GTM_PREVIEW_ENV', 'NEXT_PUBLIC_GTM_PREVIEW_AUTH', 'NEXT_PUBLIC_SEGMENT_WRITE_KEY_AU', 'NEXT_PUBLIC_SEGMENT_WRITE_KEY_NZ', 'NEXT_PUBLIC_SEGMENT_WRITE_KEY', 'NEXT_PUBLIC_SEGMENT_CDN_URL', 'NEXT_PUBLIC_SEGMENT_CDN_URL_AU', 'NEXT_PUBLIC_SEGMENT_CDN_URL_NZ', 'NEXT_PUBLIC_FEAT_FLAG_PERSONALISED_ADS', 'NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE', ...Object.keys(FeatureFlagName)];
export const CONFIG_KEYS = arrayToObject(CONFIG);
export const getClientConfigValue = configKey => {
  // Otherwise, if on the server (either Next.js or existing site), or in the browser, but without
  // window.__ENV__ defined (ie; in the browser with Next.js), then we return the value of process.env
  // We need a map of all the keys to their process.env values manually because Next JS
  // does a search and replace like operation to populate these values in the client side bundle.
  /* eslint-disable no-process-env */
  return {
    NEXT_PUBLIC_ASSET_HOST: process.env.NEXT_PUBLIC_ASSET_HOST,
    NEXT_PUBLIC_AU_HOST: process.env.NEXT_PUBLIC_AU_HOST,
    NEXT_PUBLIC_NZ_HOST: process.env.NEXT_PUBLIC_NZ_HOST,
    NEXT_PUBLIC_CONTENT_SERVICE: process.env.NEXT_PUBLIC_CONTENT_SERVICE,
    NEXT_PUBLIC_CONTENT_SERVICE_V2: process.env.NEXT_PUBLIC_CONTENT_SERVICE_V2,
    NEXT_PUBLIC_ALGOLIA_APPLICATION_ID: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
    NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
    NEXT_PUBLIC_MKP_ALGOLIA_APPLICATION_ID: process.env.NEXT_PUBLIC_MKP_ALGOLIA_APPLICATION_ID,
    NEXT_PUBLIC_MKP_ALGOLIA_SEARCH_KEY: process.env.NEXT_PUBLIC_MKP_ALGOLIA_SEARCH_KEY,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
    NEXT_PUBLIC_TOGGLE_WHATS_HAPPENING_SEE_MORE: process.env.NEXT_PUBLIC_TOGGLE_WHATS_HAPPENING_SEE_MORE,
    NEXT_PUBLIC_FEAT_FLAG_MEASUREMENT: process.env.NEXT_PUBLIC_FEAT_FLAG_MEASUREMENT,
    NEXT_PUBLIC_FEAT_FLAG_ANALYTICS_CONSOLE: process.env.NEXT_PUBLIC_FEAT_FLAG_ANALYTICS_CONSOLE,
    NEXT_PUBLIC_AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    NEXT_PUBLIC_AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    NEXT_PUBLIC_GTM_PREVIEW_ENV: process.env.NEXT_PUBLIC_GTM_PREVIEW_ENV,
    NEXT_PUBLIC_GTM_PREVIEW_AUTH: process.env.NEXT_PUBLIC_GTM_PREVIEW_AUTH,
    NEXT_PUBLIC_SEGMENT_WRITE_KEY_AU: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_AU,
    NEXT_PUBLIC_SEGMENT_WRITE_KEY_NZ: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_NZ,
    NEXT_PUBLIC_SEGMENT_WRITE_KEY_OVERRIDABLE: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_OVERRIDABLE,
    NEXT_PUBLIC_SEGMENT_CDN_URL_AU: process.env.NEXT_PUBLIC_SEGMENT_CDN_URL_AU,
    NEXT_PUBLIC_SEGMENT_CDN_URL_NZ: process.env.NEXT_PUBLIC_SEGMENT_CDN_URL_NZ,
    NEXT_PUBLIC_FEAT_FLAG_PERSONALISED_ADS: process.env.NEXT_PUBLIC_FEAT_FLAG_PERSONALISED_ADS,
    NEXT_PUBLIC_FEAT_FLAG_CONSENT_TARGETED_ADS: process.env.NEXT_PUBLIC_FEAT_FLAG_CONSENT_TARGETED_ADS,
    NEXT_PUBLIC_FEAT_FLAG_TOP_STRIP: process.env.NEXT_PUBLIC_FEAT_FLAG_TOP_STRIP,
    NEXT_PUBLIC_FEAT_FLAG_SESSION_LOGGER: process.env.NEXT_PUBLIC_FEAT_FLAG_SESSION_LOGGER,
    NEXT_PUBLIC_FEAT_FLAG_HIDE_ORDERS_IMAGE: process.env.NEXT_PUBLIC_FEAT_FLAG_HIDE_ORDERS_IMAGE,
    NEXT_PUBLIC_FEAT_FLAG_ENABLE_STORE_DIRECTORY: process.env.NEXT_PUBLIC_FEAT_FLAG_ENABLE_STORE_DIRECTORY,
    NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH: process.env.NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH,
    NEXT_PUBLIC_FEAT_FLAG_OLYMPIC_COUNTDOWN_OVERRIDE: process.env.NEXT_PUBLIC_FEAT_FLAG_OLYMPIC_COUNTDOWN_OVERRIDE,
    NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE: process.env.NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE,
    NEXT_PUBLIC_FEAT_FLAG_PARALYMPICS_OVERRIDE: process.env.NEXT_PUBLIC_FEAT_FLAG_PARALYMPICS_OVERRIDE
  }[configKey];
  /* eslint-enable no-process-env */
};

export { getEnvBool };
export const getClientConfigBool = configKey => getEnvBool(getClientConfigValue(configKey));
export const getAlgoliaApiKeys = () => ({
  contentApiKey: getClientConfigValue(CONFIG_KEYS.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY),
  contentApplicationId: getClientConfigValue(CONFIG_KEYS.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID)
});
export function getSegmentConfiguration(country) {
  const cdnURL = makeCountrySpecificValue(getClientConfigValue(CONFIG_KEYS.NEXT_PUBLIC_SEGMENT_CDN_URL_AU), getClientConfigValue(CONFIG_KEYS.NEXT_PUBLIC_SEGMENT_CDN_URL_NZ));
  const writeKey = makeCountrySpecificValue(getClientConfigValue(CONFIG_KEYS.NEXT_PUBLIC_SEGMENT_WRITE_KEY_AU), getClientConfigValue(CONFIG_KEYS.NEXT_PUBLIC_SEGMENT_WRITE_KEY_NZ));
  return {
    cdnURL: resolveCountrySpecificValue(cdnURL, country) || null,
    writeKey: resolveCountrySpecificValue(writeKey, country) || null,
    writeKeyOverridable: getClientConfigBool(CONFIG_KEYS.NEXT_PUBLIC_SEGMENT_WRITE_KEY_OVERRIDABLE)
  };
}
export const CONFIG_PROCESS_ENV_MAP = CONFIG.reduce((acc, key) => {
  acc[key] = process.env[key]; // eslint-disable-line no-process-env
  return acc;
}, {});

// In dev mode, we use http. Everywhere else, we use https
// eslint-disable-next-line no-process-env
const protocol = false ? 'http' : 'https';
export const NATIONAL_CONFIG = {
  au: {
    title: 'Westfield Australia',
    meta: [{
      name: 'description',
      content: "Find thousands of products from your favourite retailers across women's and men's fashion, kids, home and decor and more."
    }, {
      name: 'color-scheme',
      content: 'only light'
    }],
    country: 'Australia',
    lang: 'en-AU',
    url: "".concat(protocol, "://").concat(getClientConfigValue(CONFIG_KEYS.NEXT_PUBLIC_AU_HOST))
  },
  nz: {
    title: 'Westfield New Zealand',
    meta: [{
      name: 'description',
      content: "Find thousands of products from your favourite retailers across women's and men's fashion, kids, home and decor and more."
    }, {
      name: 'color-scheme',
      content: 'only light'
    }],
    country: 'New Zealand',
    lang: 'en-NZ',
    url: "".concat(protocol, "://").concat(getClientConfigValue(CONFIG_KEYS.NEXT_PUBLIC_NZ_HOST))
  }
};