import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery } from '@apollo/client';
import { GetUserDocument } from '../graphql';
import { transformUserData } from '../helpers/transforms';
import useIsMounted from './use-is-mounted';
import useIdToken from './use-id-token';
export function useUser() {
  let includeAuthHeader = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  const {
    idToken
  } = useIdToken();
  const isBrowser = useIsMounted();
  let context = {
    version: 2
  };
  if (includeAuthHeader) {
    context = _objectSpread(_objectSpread({}, context), {}, {
      headers: {
        Authorization: "Bearer ".concat(idToken)
      }
    });
  }
  const {
    data,
    loading
  } = useQuery(GetUserDocument, {
    context,
    skip: !isBrowser || !idToken,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only'
  });
  const fitleredUserData = transformUserData(data);
  return {
    user: fitleredUserData,
    loading: !isBrowser ? true : loading
  };
}