import { transformObjectActionEventForSegment } from '..';
export function transformLegacyDigitalGiftCardPurchase(data) {
  var _data$ecommerce$purch;
  const currency = data.ecommerce.currencyCode;
  const cartProducts = data.ecommerce.purchase.products.map(product => {
    const cartProduct = {
      product_id: product.id,
      brand: product.brand,
      name: product.name,
      currency,
      price: Number(product.price),
      quantity: product.quantity ? product.quantity : 1
    };
    if (product.category) {
      cartProduct.category_path = product.category;
      const maxCategoryLimit = 5;
      product.category.split('/').forEach((category, index) => {
        if (index < maxCategoryLimit && category !== '') {
          const key = "category_".concat(index + 1);
          cartProduct[key] = category;
        }
      });
    }
    return cartProduct;
  });
  const productDimensions = data.ecommerce.purchase.products[0];
  let billingDetails = undefined;
  if (productDimensions.dimension95 && productDimensions.dimension97 && productDimensions.dimension96 && productDimensions.dimension100) {
    billingDetails = {
      suburb: productDimensions.dimension95,
      postcode: productDimensions.dimension97,
      state_territory: productDimensions.dimension96,
      country: productDimensions.dimension100
    };
  }
  return transformObjectActionEventForSegment({
    object: 'Order',
    action: 'Completed',
    properties: {
      category: 'Ecommerce',
      label: 'order_completed',
      cart_id: data.ecommerce.purchase.actionField.cartId ? data.ecommerce.purchase.actionField.cartId : '',
      cart_affiliation: 'Westfield - Digital Gift Card - AU',
      currency: data.ecommerce.currencyCode,
      products: cartProducts,
      total: Number(data.ecommerce.purchase.actionField.revenue),
      revenue: Number(data.ecommerce.purchase.actionField.revenue) - Number(data.ecommerce.purchase.actionField.shipping) - Number(data.ecommerce.purchase.actionField.tax),
      tax: Number(data.ecommerce.purchase.actionField.tax),
      order_id: data.ecommerce.purchase.actionField.id,
      shipping: Number(data.ecommerce.purchase.actionField.shipping),
      billing_address: billingDetails,
      delivery_type: 'EMAIL',
      payment_method: "".concat((_data$ecommerce$purch = data.ecommerce.purchase.products[0].dimension117) === null || _data$ecommerce$purch === void 0 ? void 0 : _data$ecommerce$purch.toLowerCase(), " - unknown")
    }
  });
}